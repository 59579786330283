exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kurse-kitesurfen-js": () => import("./../../../src/pages/kurse/kitesurfen.js" /* webpackChunkName: "component---src-pages-kurse-kitesurfen-js" */),
  "component---src-pages-kurse-privatunterricht-js": () => import("./../../../src/pages/kurse/privatunterricht.js" /* webpackChunkName: "component---src-pages-kurse-privatunterricht-js" */),
  "component---src-pages-kurse-sup-js": () => import("./../../../src/pages/kurse/sup.js" /* webpackChunkName: "component---src-pages-kurse-sup-js" */),
  "component---src-pages-kurse-wellenreiten-js": () => import("./../../../src/pages/kurse/wellenreiten.js" /* webpackChunkName: "component---src-pages-kurse-wellenreiten-js" */),
  "component---src-pages-kurse-windsurfen-js": () => import("./../../../src/pages/kurse/windsurfen.js" /* webpackChunkName: "component---src-pages-kurse-windsurfen-js" */),
  "component---src-pages-kurse-wingfoilen-js": () => import("./../../../src/pages/kurse/wingfoilen.js" /* webpackChunkName: "component---src-pages-kurse-wingfoilen-js" */),
  "component---src-pages-kurstermine-js": () => import("./../../../src/pages/kurstermine.js" /* webpackChunkName: "component---src-pages-kurstermine-js" */),
  "component---src-pages-standort-js": () => import("./../../../src/pages/standort.js" /* webpackChunkName: "component---src-pages-standort-js" */),
  "component---src-pages-station-anmeldung-js": () => import("./../../../src/pages/station/anmeldung.js" /* webpackChunkName: "component---src-pages-station-anmeldung-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-vdws-versicherung-js": () => import("./../../../src/pages/vdws-versicherung.js" /* webpackChunkName: "component---src-pages-vdws-versicherung-js" */),
  "component---src-pages-verleih-js": () => import("./../../../src/pages/verleih.js" /* webpackChunkName: "component---src-pages-verleih-js" */)
}

